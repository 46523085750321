
import { Container, Row, Col } from 'react-bootstrap';
import './PrimaryActions.css';

function PrimaryActions(props) {
    return (
        // <Container>
            <Row className="justify-content-center px-4">
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="tel:+48 534 903 906">
                        <div className="img-fluid">
                            <img src="./mobile1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Mobile</div> */}
                        </div>
                    </a>
                </Col>
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="tel:+48 534 903 906">
                        <div>
                        <img src="./office1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Office</div> */}
                        </div>
                    </a>
                </Col>
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="mailto:kubarokowski@gmail.com">
                        <div>
                        <img src="./email1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Email</div> */}
                        </div>
                    </a>
                </Col>
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="https://www.google.pl/maps/place/34-120+Andrych%C3%B3w/@49.8539888,19.321384,14z/data=!3m1!4b1!4m5!3m4!1s0x47168522c93dc213:0xaf1a58e12d5ef2f2!8m2!3d49.8548934!4d19.3412842">
                        <div >
                        <img src="./map1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Map</div> */}
                        </div>
                    </a>
                </Col>
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="https://www.kubarokowski.ewizytowka.app">
                        <div >
                        <img src="./website1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Website</div> */}
                        </div>
                    </a>
                </Col>
            </Row>
        // </Container>
    )
}

export default PrimaryActions;